<template>
  <div>
    <v-app-bar
      dark
      app
      clipped-left
      dense
      color="primary"
      class="header-safe-area-fix"
    >
      <h2 class="d-flex align-center">ridgewolf</h2>
      <v-spacer></v-spacer>

      <v-btn href="#" text>
        <span class="mr-2 text-lowercase">login</span>
        <v-icon>fas fa-external-link-alt</v-icon>
      </v-btn>
    </v-app-bar>
    <v-main>
      <router-view></router-view>
    </v-main>
  </div>
</template>

<script>
export default {
  name: "PublicLayout"
}
</script>
